import React from "react";
import Header from "../../components/Header/Header";
import s from "./VacancyDetails.module.scss";
import { FilePdfOutlined, DownloadOutlined } from "@ant-design/icons";
import Footer from "../../components/Footer/Footer";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import { useSelector } from "react-redux";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import { useParams } from "react-router-dom";

const VacancyDetails = ({ data }) => {
  const vacancy = useSelector((state) => state.application.vacancyDetails);
  const { width } = useWindowDimensions();
  const params = useParams();

  const downloadFile = (type) => {
    if (type === "application" && params.id === "1") {
      const fileUrl = "/Объявление Нов (1).pdf";
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = "Заявление.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    if (type === "application" && params.id === "2") {
      const fileUrl = "/Форма_заявки_и_анкета_независимого_оценщика.pdf";
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = "Заявление.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const openFileInNewWindow = (e, type) => {
    e.preventDefault();
    if (type === "application" && params.id === "1") {
      const fileUrl = "/Объявление Нов (1).pdf";
      window.open(fileUrl, "_blank");
    }
    if (type === "application" && params.id === "2") {
      const fileUrl = "/Форма_заявки_и_анкета_независимого_оценщика.pdf";
      window.open(fileUrl, "_blank");
    }
  };

  return (
    <div className={s.vacancy_details}>
      {width > 930 ? <Header /> : <MobileHeader />}
      <div className={s.content}>
        <div className={s.category}>ВAКАНСИИ</div>
        <h2 className={s.h2}>{vacancy?.title}</h2>

        <div
          className={s.description}
          dangerouslySetInnerHTML={{ __html: vacancy?.description }}
        ></div>

        <div className={s.description}>
          <div
            className={s.subtitle}
            dangerouslySetInnerHTML={{ __html: vacancy?.details }}
          ></div>

          <div
            className={s.subtitle}
            dangerouslySetInnerHTML={{ __html: vacancy?.note }}
          ></div>
        </div>

        <div className={s.documents_btns}>
          <button
            className={s.title}
            onClick={(e) => openFileInNewWindow(e, "application")}
          >
            <FilePdfOutlined className={s.icon} /> &nbsp;
            {params.id === "1"
              ? "ЗАЯВКА КОНСУЛЬТАНТА НА ПРОХОЖДЕНИЕ АККРЕДИТАЦИИ"
              : "ФОРМА ЗАЯВКИ И АНКЕТА НЕЗАВИСИМОГО ОЦЕНЩИКА"}
          </button>
          <div className={s.btn} onClick={() => downloadFile("application")}>
            <DownloadOutlined />
          </div>
        </div>
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default VacancyDetails;
