import React from "react";
import FadeLoader from "react-spinners/FadeLoader";

const Loading = ({ loading }) => {
  const override = {
    display: "block",
    margin: "0 auto",
  };

  return (
    <div>
      <FadeLoader
        loading={loading}
        color={"#7f0f0f"}
        cssOverride={override}
        size={40}
      />
    </div>
  );
};

export default Loading;
