import React, { useState } from "react";
import s from "./FAQ.module.scss";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import {
  QuestionCircleOutlined,
  EditOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
} from "@ant-design/icons";

const FAQ = () => {
  const [openedAccordionKey, setOpenedAccordionKey] = useState(0);

  return (
    <div className={s.faq}>
      <div className={s.wrapper}>
        <h2 className={s.h2} data-aos='fade-up' data-aos-duration='2000'>
          Часто задаваемые вопросы&nbsp;
          <QuestionCircleOutlined className={s.question_icon} />{" "}
        </h2>

        <div className={s.subtitle} data-aos='fade-up' data-aos-duration='3000'>
          <EditOutlined /> &nbsp;Задайте нам вопросы и ответы на самые
          популярные будут опубликованы.
        </div>
        <Accordion
          allowZeroExpanded
          className={s.accordion}
          allowMultipleExpanded={false}
        >
          <AccordionItem key={1} className={s.item}>
            <AccordionItemHeading
              className={s.heading}
              onClick={(e) => setOpenedAccordionKey(1)}
            >
              <AccordionItemButton className={s.btn}>
                <div>1. Какая максимальная сумма финансирования проектов?</div>
                <div>
                  {openedAccordionKey === 1 ? (
                    <CaretUpOutlined className={s.icon} />
                  ) : (
                    <CaretDownOutlined className={s.icon} />
                  )}
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={s.panel}>
              Сумма финансирования составляет до 3 000 000 долларов США до 5
              лет.
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem key={2} className={s.item}>
            <AccordionItemHeading
              className={s.heading}
              onClick={(e) => setOpenedAccordionKey(2)}
            >
              <AccordionItemButton className={s.btn}>
                <div>2. Какие продукты имеются у вас?</div>
                <div>
                  {openedAccordionKey === 2 ? (
                    <CaretUpOutlined className={s.icon} />
                  ) : (
                    <CaretDownOutlined className={s.icon} />
                  )}
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={s.panel}>
              На данный момент существует только кредитное финансирование. В
              ближайшее время будем рассматривать другие виды проектного
              Финансирования, Участие в капитале, Лизинг и Startup
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem key={3} className={s.item}>
            <AccordionItemHeading
              className={s.heading}
              onClick={(e) => setOpenedAccordionKey(3)}
            >
              <AccordionItemButton className={s.btn}>
                <div>3. Какие у вас процентные ставки?</div>
                <div>
                  {openedAccordionKey === 3 ? (
                    <CaretUpOutlined className={s.icon} />
                  ) : (
                    <CaretDownOutlined className={s.icon} />
                  )}
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={s.panel}>
              Наши процентные ставки составляют 5% - 6% годовых
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem key={4} className={s.item}>
            <AccordionItemHeading
              className={s.heading}
              onClick={(e) => setOpenedAccordionKey(4)}
            >
              <AccordionItemButton className={s.btn}>
                <div>4. Укажите валюту и максимальный срок финансирования</div>
                <div>
                  {openedAccordionKey === 4 ? (
                    <CaretUpOutlined className={s.icon} />
                  ) : (
                    <CaretDownOutlined className={s.icon} />
                  )}
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={s.panel}>
              Рассматриваем кредиты только в долларах США до 60 месяцев.
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem key={5} className={s.item}>
            <AccordionItemHeading
              className={s.heading}
              onClick={(e) => setOpenedAccordionKey(5)}
            >
              <AccordionItemButton className={s.btn}>
                <div>5. Предоставляется ли льготный период по возврату?</div>
                <div>
                  {openedAccordionKey === 5 ? (
                    <CaretUpOutlined className={s.icon} />
                  ) : (
                    <CaretDownOutlined className={s.icon} />
                  )}
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={s.panel}>
              Льготный период по погашению основной суммы финансирования до 12
              месяцев
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem key={6} className={s.item}>
            <AccordionItemHeading
              className={s.heading}
              onClick={(e) => setOpenedAccordionKey(6)}
            >
              <AccordionItemButton className={s.btn}>
                <div>
                  6. Способ обеспечения исполнения обязательств (залог,
                  поручительство или другие)?
                </div>
                <div>
                  {openedAccordionKey === 6 ? (
                    <CaretUpOutlined className={s.icon} />
                  ) : (
                    <CaretDownOutlined className={s.icon} />
                  )}
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={s.panel}>
              Залоговым обеспечением являются: <br />
              • недвижимое имущество <br />
              • движимое имущество (за исключением автомобилей) <br />
              • гарантии банков или Гарантийных фондов <br />
              • другое имущество и/или имущественные права <br />
              • иные инструменты обеспечения, предусмотренные применимым
              законодательством и требованиям Фонда. <br />
              Обязательно проведение независимой оценки залогового обеспечения и
              приобретаемых активов в рамках предоставляемого кредита. Оценочная
              компания должна быть аккредитована в Фонде.
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem key={7} className={s.item}>
            <AccordionItemHeading
              className={s.heading}
              onClick={(e) => setOpenedAccordionKey(7)}
            >
              <AccordionItemButton className={s.btn}>
                <div>
                  7. Какие требуются документы: бизнес-план, ТЭО и др.?{" "}
                </div>
                <div>
                  {openedAccordionKey === 7 ? (
                    <CaretUpOutlined className={s.icon} />
                  ) : (
                    <CaretDownOutlined className={s.icon} />
                  )}
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={s.panel}>
              Для получения финансирования первоначально требуются следующие
              документы: <br />
              • Заявка на получение финансирования <br />
              • Бизнес-план проекта (ТЭО) с финансовой моделью <br />
              • Предварительное подтверждение структуры затрат <br />
              • Финансовые документы Клиента (+группы связанных компаний) и
              поручителя (при наличии такового) <br />
              • Учредительные документы заемщика (+группы связанных компаний),
              поручителя и залогодателя <br />
              • Перечень исходно-разрешительной документации, полученной
              Заемщиком <br />
              • Список предлагаемого залогового имущества <br />
              • Сведения о ключевой команде проекта <br />• Копии паспортов
              участников, руководителей, главных бухгалтеров <br />
              • Справка с МВД об отсутствии судимости <br />
              • Заявление-согласие на получение информации в КИБ  <br />
              Учитывайте, что процессе подготовки анализа возможен запрос
              дополнительных документов.
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem key={8} className={s.item}>
            <AccordionItemHeading
              className={s.heading}
              onClick={(e) => setOpenedAccordionKey(8)}
            >
              <AccordionItemButton className={s.btn}>
                <div>8. Укажите целевое назначение кредитов</div>
                <div>
                  {openedAccordionKey === 8 ? (
                    <CaretUpOutlined className={s.icon} />
                  ) : (
                    <CaretDownOutlined className={s.icon} />
                  )}
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={s.panel}>
              Целевым назначением кредитов является: <br />
              • приобретение оборудования и техники <br />
              • выполнение строительно-монтажных работ
              <br />• приобретение объектов недвижимости (в рамках проекта)
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem key={9} className={s.item}>
            <AccordionItemHeading
              className={s.heading}
              onClick={(e) => setOpenedAccordionKey(9)}
            >
              <AccordionItemButton className={s.btn}>
                <div>
                  9. Требуется ли обязятельное страхование залогового
                  обеспечения?
                </div>
                <div>
                  {openedAccordionKey === 9 ? (
                    <CaretUpOutlined className={s.icon} />
                  ) : (
                    <CaretDownOutlined className={s.icon} />
                  )}
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={s.panel}>
              Необходимо обязательное страхование залогового обеспечения
              компаниями, аккредитованными в Фонде
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem key={10} className={s.item}>
            <AccordionItemHeading
              className={s.heading}
              onClick={(e) => setOpenedAccordionKey(10)}
            >
              <AccordionItemButton className={s.btn}>
                <div>10. Какой процент собственного вклада ?</div>
                <div>
                  {openedAccordionKey === 10 ? (
                    <CaretUpOutlined className={s.icon} />
                  ) : (
                    <CaretDownOutlined className={s.icon} />
                  )}
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={s.panel}>
              Cобственный вклад должен составлять не менее 30% от стоимости
              проекта
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem key={11} className={s.item}>
            <AccordionItemHeading
              className={s.heading}
              onClick={(e) => setOpenedAccordionKey(11)}
            >
              <AccordionItemButton className={s.btn}>
                <div>
                  11. Какой процент за несвоевременное исполнение обязятельств?
                </div>
                <div>
                  {openedAccordionKey === 11 ? (
                    <CaretUpOutlined className={s.icon} />
                  ) : (
                    <CaretDownOutlined className={s.icon} />
                  )}
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={s.panel}>
              Неустойка за несвоевременное исполнение /неисполнение
              обязательств, связанных с возвратом кредита и уплатой начисленных
              за пользование кредитом процентов составляет 20 % годовых
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem key={12} className={s.item}>
            <AccordionItemHeading
              className={s.heading}
              onClick={(e) => setOpenedAccordionKey(12)}
            >
              <AccordionItemButton className={s.btn}>
                <div>12. Существует ли компонент рефинансирования?</div>
                <div>
                  {openedAccordionKey === 12 ? (
                    <CaretUpOutlined className={s.icon} />
                  ) : (
                    <CaretDownOutlined className={s.icon} />
                  )}
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={s.panel}>
              Компонент рефинансирования отсутствует
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
};

export default FAQ;
