import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import s from "./ApprovedProjects.module.scss";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";

const RealizedProjects = () => {
  const { width } = useWindowDimensions();
  return (
    <div>
      {width > 930 ? <Header /> : <MobileHeader />}
      <div className={s.projects}>
        <div>
          <img src='/favicon.png' className={s.favicon} />
        </div>
        <div className={s.text}>Пока нет реализованных проектов ...</div>
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default RealizedProjects;
