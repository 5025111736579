import React, { useState } from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import {
  AuditOutlined,
  ContactsOutlined,
  DownOutlined,
  FileDoneOutlined,
  FilePdfFilled,
  FilePdfOutlined,
  FileProtectOutlined,
  FileSyncOutlined,
  HomeOutlined,
  IdcardOutlined,
  NotificationOutlined,
  TeamOutlined,
  UserAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import useWindowDimensions from "../WindowDimensions/WindowDimensions";
import ApplicationDrawer from "../ApplicationDrawer/ApplicationDrawer";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { height } = useWindowDimensions();
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const redirectToPage = (page) => {
    if (page === "about") {
      navigate("/about");
    }
    if (page === "team") {
      navigate("/team");
    }
    if (page === "reports") {
      navigate("/reports");
    }
    if (page === "companies") {
      navigate("/companies");
    }
    if (page === "consultants") {
      navigate("/consultants");
    }
    if (page === "vacancy") {
      navigate("/vacancies");
    }
  };

  const options1 = (
    <Menu>
      <Menu.Item key='1' onClick={(e) => redirectToPage("about")}>
        <HomeOutlined className={"icon"} />О фонде
      </Menu.Item>
      <Menu.Item key='2' onClick={(e) => redirectToPage("team")}>
        <UserOutlined className={"icon"} />
        Руководство
      </Menu.Item>
      <Menu.Item key='3' onClick={(e) => redirectToPage("reports")}>
        <FileProtectOutlined className={"icon"} />
        Нормативные акты
      </Menu.Item>
      <Menu.Item key='4' onClick={(e) => redirectToPage("vacancy")}>
        <UserAddOutlined className={"icon"} />
        Вакансии
      </Menu.Item>
    </Menu>
  );

  const options2 = (
    <Menu>
      <Menu.Item key='1'>
        <Link to='/apprisers'>
          <IdcardOutlined className={"icon"} />
          Аккредитованные оценщики
        </Link>
      </Menu.Item>
      <Menu.Item key='2'>
        <Link to='/consultants'>
          <ContactsOutlined className={"icon"} /> Аккредитованные консультанты
        </Link>
      </Menu.Item>
      <Menu.Item key='3'>
        <Link to='/insuranceCompanies'>
          <ContactsOutlined className={"icon"} /> Аккредитованные страховые
          компании
        </Link>
      </Menu.Item>
      <Menu.Item key='4'>
        <Link to='/partnersPage'>
          <TeamOutlined className={"icon"} />
          Партнеры
        </Link>
      </Menu.Item>
    </Menu>
  );

  const options3 = (
    <Menu>
      <Menu.Item key='2'>
        <div onClick={() => showDrawer(true)}>
          {" "}
          <FileSyncOutlined className={"icon"} /> Предварительная заявка
        </div>
      </Menu.Item>
      <Menu.Item key='1'>
        <Link to='/requirements'>
          <FilePdfOutlined className={"icon"} /> Условия инвестиционного
          кредитования
        </Link>
      </Menu.Item>
      <Menu.Item key='3'>
        <Link to='/approvedProjects'>
          <AuditOutlined className={"icon"} />
          Утвержденные проекты
        </Link>
      </Menu.Item>
      <Menu.Item key='4'>
        <Link to='/realizedProjects'>
          <FileDoneOutlined className={"icon"} />
          Реализованные проекты
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className='header'>
      <div className='logo'>
        <Link to='/' className='logo_link'>
          <img src='/dark_logo.png' className='header_logo' />
        </Link>
      </div>
      <div className={"menu_wrapper"}>
        <ul className={"menu"}>
          <Dropdown overlay={options1}>
            <ol className={location.pathname === "/about" ? "active" : ""}>
              О фонде
            </ol>
          </Dropdown>
          <Dropdown overlay={options3}>
            <ol className={location.pathname === "/projects" ? "active" : ""}>
              Проекты
            </ol>
          </Dropdown>

          <ol className={location.pathname === "/tenders" ? "active" : ""}>
            <Link to='/tenders'>Тендеры</Link>
          </ol>

          <Dropdown overlay={options2}>
            <ol className={location.pathname === "/about" ? "active" : ""}>
              Партнеры
            </ol>
          </Dropdown>
          <ol className={location.pathname === "/news" ? "active" : ""}>
            <Link to='/news'>Новости</Link>
          </ol>
        </ul>
      </div>
      <ApplicationDrawer open={open} onClose={onClose} />
    </div>
  );
};

export default Header;
