import "./App.scss";

import { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import News from "./pages/News/News";
import Tenders from "./pages/Tenders/Tenders";
import TenderDetails from "./pages/TenderDetails/TenderDetails";
import NewsDetails from "./pages/NewsDetails/NewsDetails";
import AboutUs from "./pages/AboutUs/AboutUs";
import Reports from "./pages/Reports/Reports";
import Team from "./pages/Team/Team";
import Vacancies from "./pages/Vacancies/Vacancies";
import VacancyDetails from "./pages/VacancyDetails/VacancyDetails";
import Projects from "./pages/Projects/Projects";
import ApprovedProjects from "./pages/ApprovedProjects/ApprovedProjects";
import RealizedProjects from "./pages/RealizedProjects/RealizedProjects";
import Apprisers from "./pages/Appraisers/Apprisers";
import Consultants from "./pages/Consultants/Consultants";

import Requirements from "./pages/Requirements/Requirements";
import PartnersPage from "./pages/PartnersPage/PartnersPage";
import InsuranceCompanies from "./pages/InsuranceCompanies/InsuranceCompanies";

function App() {
  return (
    <BrowserRouter>
      <Suspense>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/news' element={<News />} />
          <Route path='/team' element={<Team />} />
          <Route path='/about' element={<AboutUs />} />
          <Route path='/reports' element={<Reports />} />
          <Route path='/vacancies' element={<Vacancies />} />
          <Route path='/tenders' element={<Tenders />} />
          <Route path='/projects' element={<Projects />} />
          <Route path='/tenders/:id' element={<TenderDetails />} />
          <Route path='/news/:id' element={<NewsDetails />} />
          <Route path='/vacancies/:id' element={<VacancyDetails />} />
          <Route path='/approvedProjects' element={<ApprovedProjects />} />
          <Route path='/realizedProjects' element={<RealizedProjects />} />
          <Route path='/consultants' element={<Consultants />} />
          <Route path='/apprisers' element={<Apprisers />} />
          <Route path='/partnersPage' element={<PartnersPage />} />
          <Route path='/requirements' element={<Requirements />} />
          <Route path='/insuranceCompanies' element={<InsuranceCompanies />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
