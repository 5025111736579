import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { publicRequest } from "../utils";
import InsuranceCompanies from "../pages/InsuranceCompanies/InsuranceCompanies";

const initialState = {
  application: {},
  news: [],
  newsDetails: {},
  slider: [],
  tenders: [],
  tenderDetails: {},
  vacancies: [],
  vacancyDetails: {},
  feedback: {},
  boardMembers: [],
  councilMembers: [],
  reports: [],
  contacts: {},
  loading: false,
  consultants: [],
  appraisers: [],
  insuranceCompanies: [],
};

export const sendApplication = createAsyncThunk(
  "application/sendApplication",
  async (info) => {
    try {
      const { data } = await publicRequest.post(
        `main/api/v1/applications/`,
        info
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const sendFeedback = createAsyncThunk(
  "application/sendFeedback",
  async (info) => {
    try {
      const { data } = await publicRequest.post(
        `content/api/v1/feedback/`,
        info
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getNews = createAsyncThunk("main/getNews", async () => {
  try {
    const { data } = await publicRequest.get(`content/api/v1/news/`);
    return data;
  } catch (error) {
    console.log(error);
  }
});

export const getNewsDetails = createAsyncThunk(
  "main/getNewsDetails",
  async (id) => {
    try {
      const { data } = await publicRequest.get(`content/api/v1/news/${id}/`);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getSlider = createAsyncThunk("main/getSlider", async (id) => {
  try {
    const { data } = await publicRequest.get(`main/api/v1/main-slider/`);
    return data;
  } catch (error) {
    console.log(error);
  }
});

export const getTenders = createAsyncThunk("main/tenders", async () => {
  try {
    const { data } = await publicRequest.get(`tender/api/v1/tenders/`);
    return data;
  } catch (error) {
    console.log(error);
  }
});

export const getTenderDetails = createAsyncThunk("main/tender", async (id) => {
  try {
    const { data } = await publicRequest.get(`tender/api/v1/tenders/${id}/`);
    return data;
  } catch (error) {
    console.log(error);
  }
});

export const getVacancies = createAsyncThunk("main/vacancies", async () => {
  try {
    const { data } = await publicRequest.get(`content/api/v1/vacancy/`);
    return data;
  } catch (error) {
    console.log(error);
  }
});

export const getVacancyDetails = createAsyncThunk(
  "main/vacancyDetails",
  async (id) => {
    try {
      const { data } = await publicRequest.get(`content/api/v1/vacancy/${id}/`);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getBoardMembers = createAsyncThunk(
  "main/boardMembers",
  async () => {
    try {
      const { data } = await publicRequest.get(
        `content/api/v1/board-of-directors/`
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getCouncilMembers = createAsyncThunk(
  "main/councilMembers",
  async () => {
    try {
      const { data } = await publicRequest.get(
        `content/api/v1/fund-council-members/`
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getReports = createAsyncThunk("main/reports", async () => {
  try {
    const { data } = await publicRequest.get(
      `content/api/v1/normative-documents/`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
});

export const getContacts = createAsyncThunk("main/contacts", async () => {
  try {
    const { data } = await publicRequest.get(`content/api/v1/contacts/1/`);
    return data;
  } catch (error) {
    console.log(error);
  }
});

export const getConsultants = createAsyncThunk("main/consultants", async () => {
  try {
    const { data } = await publicRequest.get(`content/api/v1/consultants/`);
    return data;
  } catch (error) {
    console.log(error);
  }
});

export const getApprisers = createAsyncThunk("main/apprisers", async () => {
  try {
    const { data } = await publicRequest.get(`content/api/v1/appraisers/`);
    return data;
  } catch (error) {
    console.log(error);
  }
});

export const getInsuranceCompanies = createAsyncThunk(
  "main/insuranceCompanies",
  async () => {
    try {
      const { data } = await publicRequest.get(
        `content/api/v1/insurance-company/`
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getNews.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNews.fulfilled, (state, action) => {
        state.news = action.payload;
        state.loading = false;
      })
      .addCase(getNews.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getNewsDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNewsDetails.fulfilled, (state, action) => {
        state.newsDetails = action.payload;
        state.loading = false;
      })
      .addCase(getNewsDetails.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getSlider.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSlider.fulfilled, (state, action) => {
        state.slider = action.payload;
        state.loading = false;
      })
      .addCase(getSlider.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getTenders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTenders.fulfilled, (state, action) => {
        state.tenders = action.payload;
        state.loading = false;
      })
      .addCase(getTenders.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getTenderDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTenderDetails.fulfilled, (state, action) => {
        state.tenderDetails = action.payload;
        state.loading = false;
      })
      .addCase(getTenderDetails.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getVacancies.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVacancies.fulfilled, (state, action) => {
        state.vacancies = action.payload;
        state.loading = false;
      })
      .addCase(getVacancies.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getVacancyDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVacancyDetails.fulfilled, (state, action) => {
        state.vacancyDetails = action.payload;
        state.loading = false;
      })
      .addCase(getVacancyDetails.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getReports.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReports.fulfilled, (state, action) => {
        state.reports = action.payload;
        state.loading = false;
      })
      .addCase(getReports.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getBoardMembers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBoardMembers.fulfilled, (state, action) => {
        state.boardMembers = action.payload;
        state.loading = false;
      })
      .addCase(getBoardMembers.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getCouncilMembers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCouncilMembers.fulfilled, (state, action) => {
        state.councilMembers = action.payload;
        state.loading = false;
      })
      .addCase(getCouncilMembers.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getContacts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getContacts.fulfilled, (state, action) => {
        state.contacts = action.payload;
        state.loading = false;
      })
      .addCase(getContacts.rejected, (state) => {
        state.loading = false;
      })

      .addCase(sendApplication.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendApplication.fulfilled, (state, action) => {
        state.application = action.payload;
        state.loading = false;
      })
      .addCase(sendApplication.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getConsultants.fulfilled, (state, action) => {
        state.consultants = action.payload;
      })

      .addCase(getApprisers.fulfilled, (state, action) => {
        state.appraisers = action.payload;
      })

      .addCase(getInsuranceCompanies.fulfilled, (state, action) => {
        state.insuranceCompanies = action.payload;
      });
  },
});

export default applicationSlice.reducer;
