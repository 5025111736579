import { Table, Tabs, Tag } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getTenderDetails } from "../../redux/ApplicationSlice";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  IssuesCloseOutlined,
} from "@ant-design/icons";
import "./MobileTenderTable.scss";

const MobileTenderTable = () => {
  const columns = [
    {
      title: "Наименование тендера",
      dataIndex: `tender_name`,
      width: "40%",
    },
    {
      title: "",
      dataIndex: `tender_info`,
    },
  ];

  const dispatch = useDispatch();
  const tenders = useSelector((state) => state.application.tenders);

  return (
    <div className={"mobile_tender"}>
      <Tabs defaultActiveKey='1' type='card'>
        <TabPane tab='Актуальные' key='1'>
          <Table
            bordered
            pagination={false}
            columns={columns}
            className={"mobile_tender_table"}
            dataSource={
              Array.isArray(tenders) &&
              tenders
                ?.filter((el) => el?.status === "Актуальный")
                ?.map((it, index) => ({
                  number: <div className={"mobile_td"}>{index + 1}</div>,
                  tender_name: (
                    <Link
                      to={`/tenders/${it.id}`}
                      className={"mobile_tender_name"}
                      onClick={() => dispatch(getTenderDetails(it.id))}
                    >
                      {it?.title}
                    </Link>
                  ),
                  tender_info: (
                    <div>
                      <div className='tender_item'>
                        <div className='item_title'>Номер тендера:</div>
                        <div className='item_value'>№ {it?.tender_no}</div>
                      </div>
                      <div className='tender_item'>
                        <div className='item_title'>Категория:</div>
                        <div className='item_value'>{it?.category?.name}</div>
                      </div>
                      <div className='tender_item'>
                        <div className='item_title'>Опубликовано:</div>
                        <div className='item_value'>{it?.published_date}</div>
                      </div>
                      <div className='tender_item'>
                        <div className='item_title'>Крайний срок подачи:</div>
                        <div className='item_value'>{it?.deadline}</div>
                      </div>
                      <div className='tender_item'>
                        <div className='item_title'>Тип объявления:</div>{" "}
                        <div className='item_value'>
                          {it?.announcement_type}
                        </div>
                      </div>
                      <div className='tender_item'>
                        <div className='item_title'>Статус:</div>{" "}
                        <div className='item_value'>
                          <Tag color='#7eca8f' className='mobile_tag'>
                            <CheckCircleOutlined />
                            &nbsp;Актуальный
                          </Tag>
                        </div>
                      </div>
                    </div>
                  ),
                }))
            }
          />
        </TabPane>
        <TabPane tab='Итоги' key='2'>
          <Table
            bordered
            pagination={false}
            columns={columns}
            className={"mobile_tender_table"}
            dataSource={
              Array.isArray(tenders) &&
              tenders
                .filter((el) => el.status === "Итоги")
                .map((it, index) => ({
                  number: <div className={"mobile_td"}>{index + 1}</div>,
                  tender_name: (
                    <Link
                      to={`/tenders/${it.id}`}
                      className={"mobile_tender_name"}
                      onClick={() => dispatch(getTenderDetails(it.id))}
                    >
                      {it?.title}
                    </Link>
                  ),
                  tender_info: (
                    <div>
                      <div className='tender_item'>
                        <div className='item_title'>Номер тендера:</div>
                        <div className='item_value'>{it?.tender_no}</div>
                      </div>
                      <div className='tender_item'>
                        <div className='item_title'>Категория:</div>
                        <div className='item_value'>{it?.category?.name}</div>
                      </div>
                      <div className='tender_item'>
                        <div className='item_title'>Опубликовано:</div>
                        <div className='item_value'>{it?.published_date}</div>
                      </div>
                      <div className='tender_item'>
                        <div className='item_title'>Крайний срок подачи:</div>
                        <div className='item_value'>{it?.deadline}</div>
                      </div>
                      <div className='tender_item'>
                        <div className='item_title'>Тип объявления:</div>
                        <div className='item_value'>
                          {it?.announcement_type}
                        </div>
                      </div>
                      <div className='tender_item'>
                        <div className='item_title'>Тип объявления:</div>
                        <div className='item_value'>
                          {it?.announcement_type}
                        </div>
                      </div>
                      <div className='tender_item'>
                        <div className='item_title'>Статус:</div>{" "}
                        <div className='item_value'>
                          <Tag
                            color='rgba(239, 91, 91, 0.927)'
                            className='mobile_tag'
                          >
                            <ExclamationCircleOutlined />
                            &nbsp;Итоги
                          </Tag>
                        </div>
                      </div>
                    </div>
                  ),
                }))
            }
          />
        </TabPane>
        <TabPane tab='Завершенные' key='3'>
          <Table
            bordered
            pagination={false}
            columns={columns}
            className={"mobile_tender_table"}
            dataSource={
              Array.isArray(tenders) &&
              tenders
                .filter((el) => el.status === "Завершен")
                .map((it, index) => ({
                  number: <div className={"mobile_td"}>{index + 1}</div>,
                  tender_name: (
                    <Link
                      to={`/tenders/${it.id}`}
                      className={"mobile_name"}
                      onClick={() => dispatch(getTenderDetails(it.id))}
                    >
                      {it?.title}
                    </Link>
                  ),
                  tender_info: (
                    <div>
                      <div className='tender_item'>
                        <div className='item_title'>Номер тендера:</div>
                        <div className='item_value'>{it?.tender_no}</div>
                      </div>
                      <div className='tender_item'>
                        <div className='item_title'>Категория:</div>
                        <div className='item_value'>{it?.category?.name}</div>
                      </div>
                      <div className='tender_item'>
                        <div className='item_title'>Опубликовано:</div>
                        <div className='item_value'>{it?.published_date}</div>
                      </div>
                      <div className='tender_item'>
                        <div className='item_title'>Крайний срок подачи:</div>
                        <div className='item_value'>{it?.deadline}</div>
                      </div>
                      <div className='tender_item'>
                        <div className='item_title'>Тип объявления:</div>{" "}
                        <div className='item_value'>
                          {it?.announcement_type}
                        </div>
                      </div>
                      <div className='tender_item'>
                        <div className='item_title'>Статус:</div>{" "}
                        <div className='item_value'>
                          <Tag color='#82c9d5' className='mobile_tag'>
                            <IssuesCloseOutlined />
                            &nbsp;Завершен
                          </Tag>
                        </div>
                      </div>
                    </div>
                  ),
                }))
            }
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default MobileTenderTable;
