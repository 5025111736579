import React, { useState } from "react";
import s from "./MobileHeader.module.scss";
import { Modal } from "antd";
import { Link } from "react-router-dom";
import {
  HomeOutlined,
  ApartmentOutlined,
  TeamOutlined,
  BankOutlined,
  AuditOutlined,
  LinkOutlined,
  BookOutlined,
  EditOutlined,
  IdcardOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import ApplicationDrawer from "../ApplicationDrawer/ApplicationDrawer";

const MobileHeader = () => {
  const [isModalOpen, setIsModalOpen] = useState();
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className={s.mobileHeader}>
      <Link to='/' className={s.logo}>
        <div className={s.logo_img}>
          <img src='/dark_logo.png' className={s.header_logo} />
        </div>
      </Link>
      <div className={s.mobile_menu} onClick={() => setIsModalOpen(true)}>
        <div className=''></div>
        <div></div>
      </div>

      <Modal
        width={400}
        height={280}
        onCancel={() => setIsModalOpen(false)}
        style={{ borderRadius: "10px", width: "90%", margin: "0 auto" }}
        cancelButtonProps={{ style: { display: "none", color: "whitesmoke" } }}
        okButtonProps={{ style: { display: "none" } }}
        className={s.mobile_menu_modal}
        open={isModalOpen}
      >
        <form className='mobile_header' onSubmit={(e) => e.preventDefault()}>
          <Link
            to='/'
            className={s.mobile_menu_item}
            onClick={() => setIsModalOpen(false)}
          >
            <div className='slash'>
              <HomeOutlined className={s.icon} />
            </div>
            Главная
          </Link>
          <Link
            to='/about'
            className={s.mobile_menu_item}
            onClick={() => setIsModalOpen(false)}
          >
            <div className='slash'>
              <ApartmentOutlined className={s.icon} />
            </div>
            О фонде
          </Link>
          <Link
            to='/team'
            className={s.mobile_menu_item}
            onClick={() => setIsModalOpen(false)}
          >
            <div className='slash'>
              <TeamOutlined className={s.icon} />
            </div>
            Руководство
          </Link>
          <Link
            to='/reports'
            className={s.mobile_menu_item}
            onClick={() => setIsModalOpen(false)}
          >
            <div className='slash'>
              <BookOutlined className={s.icon} />
            </div>
            Нормативные акты
          </Link>
          <div
            className={s.mobile_menu_item}
            onClick={() => {
              showDrawer(true);
              setIsModalOpen(false);
            }}
          >
            <div className='slash'>
              <EditOutlined className={s.icon} />
            </div>
            Предварительная заявка
          </div>
          <Link
            to='/requirements'
            className={s.mobile_menu_item}
            onClick={() => setIsModalOpen(false)}
          >
            <div className='slash'>
              <BankOutlined className={s.icon} />
            </div>
            Условия инвестиционного кредитования
          </Link>
          <Link
            to='/apprisers'
            className={s.mobile_menu_item}
            onClick={() => setIsModalOpen(false)}
          >
            <div className='slash'>
              <IdcardOutlined className={s.icon} />
            </div>
            Аккредитованные оценщики
          </Link>
          <Link
            to='/consultants'
            className={s.mobile_menu_item}
            onClick={() => setIsModalOpen(false)}
          >
            <div className='slash'>
              <IdcardOutlined className={s.icon} />
            </div>
            Аккредитованные консультанты
          </Link>
          <Link
            to='/partnersPage'
            className={s.mobile_menu_item}
            onClick={() => setIsModalOpen(false)}
          >
            <div className='slash'>
              <TeamOutlined className={s.icon} />
            </div>
            Партнеры
          </Link>

          <Link
            to='/news'
            className={s.mobile_menu_item}
            onClick={() => setIsModalOpen(false)}
          >
            <div className='slash'>
              <NotificationOutlined className={s.icon} />
            </div>
            Новости
          </Link>
          <Link
            to='/tenders'
            className={s.mobile_menu_item}
            onClick={() => setIsModalOpen(false)}
          >
            <div className='slash'>
              <AuditOutlined className={s.icon} />
            </div>
            Тендеры
          </Link>
          <Link
            to='/vacancies'
            className={s.mobile_menu_item}
            onClick={() => setIsModalOpen(false)}
          >
            <div className='slash'>
              <LinkOutlined className={s.icon} />
            </div>
            Вакансии
          </Link>
        </form>
      </Modal>
      <ApplicationDrawer open={open} onClose={onClose} />
    </div>
  );
};

export default MobileHeader;
