import React from "react";
import { Steps } from "antd";
import "./Mission.scss";
import ScrollToTop from "../ScrollToTop/ScrollToTop";

const Mission = () => {
  return (
    <>
      <div className={"introduction"}>
        Целью Азербайджано-Кыргызского Фонда развития является содействие
        экономическому сотрудничеству между Кыргызской Республикой и
        Азербайджанской Республикой, модернизации и развитию кыргызской
        экономики, эффективному использованию двусторонних возможностей.
        <br />
        Для достижения обозначенных целей Фонд:
      </div>

      <Steps
        progressDot
        current={5}
        direction='vertical'
        items={[
          {
            title:
              "Осуществляет финансирование самоокупаемых проектов в приоритетных секторах экономики Кыргызской Республики.",
            // description: 'Осуществляет финансирование самоокупаемых проектов в приоритетных секторах экономики Кыргызской Республики',
          },
          {
            title:
              "Участвует в капитале хозяйствующих субъектов, зарегистрированных и ведущих свою деятельность на территории Кыргызской Республики",
            // description: 'зарегистрированных и ведущих свою деятельность в Кыргызской Республике',
          },
          {
            title:
              "Оказывает содействие хозяйствующим субъектам, зарегистрированных и ведущих свою деятельность на территории Кыргызской Республики, в осуществлении долгосрочных проектов на приемлемых финансовых условиях",
            // description: 'зарегистрированным и ведущим свою деятельность в Кыргызской Республике, в получении средне- и долгосрочных кредитов на приемлемых финансовых условиях',
          },
          {
            title:
              "участвует в развитии финансового сектора экономики Кыргызской Республики, в том числе в оказании содействия внедрению субъектами — получателями средств Фонда новых видов финансовых услуг и продуктов;",
            // description: 'современных методов корпоративного управления',
          },
          {
            title:
              "оказывает содействие в использовании его партнерами и клиентами современных методов корпоративного управления",
            // description: 'современных методов корпоративного управления',
          },
        ]}
      />
      <ScrollToTop />
    </>
  );
};

export default Mission;
