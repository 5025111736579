import React, { useState } from "react";
import Header from "../../components/Header/Header";
import s from "./Reports.module.scss";
import { FilePdfOutlined, DownloadOutlined } from "@ant-design/icons";
import Footer from "../../components/Footer/Footer";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import { useSelector } from "react-redux";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";

const Reports = () => {
  const { height, width } = useWindowDimensions();

  const reports = useSelector((state) => state.application.reports);

  const downloadFile = async (id) => {
    if (id === 1) {
      const fileUrl = "/agreement.pdf";
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = "СОГЛАШЕНИЕ.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    if (id === 2) {
      const fileUrl = "/УСТАВ.pdf";
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = "УСТАВ.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    if (id === 3) {
      const fileUrl = "/Инвестиционная_политика.pdf";
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = "/Инвестиционная_политика.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    if (id === 4) {
      const fileUrl = "/Критерии_отбора_независимых_оценщиков.pdf";
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = "/Критерии_отбора_независимых_оценщиков.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    if (id === 5) {
      const fileUrl = "/Критерии_отбора_страховых_компаний.pdf";
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = "/Критерии_отбора_страховых_компаний.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    if (id === 6) {
      const fileUrl =
        "БАЗОВЫЕ_КРИТЕРИИ_АККРЕДИТАЦИИ_КОНСУЛЬТАНТА_И_СПИСОК_НЕОБХОДИМЫХ (7) (1).pdf";
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download =
        "БАЗОВЫЕ_КРИТЕРИИ_АККРЕДИТАЦИИ_КОНСУЛЬТАНТА_И_СПИСОК_НЕОБХОДИМЫХ (7) (1).pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const openFileInNewWindow = (e, file) => {
    e.preventDefault();
    const fileUrl = file;
    window.open(fileUrl, "_blank");
  };

  return (
    <div className={s.reports_wrapper}>
      {width > 930 ? <Header /> : <MobileHeader />}
      <div className={s.reports}>
        <h2 className={s.h2}>Нормативные документы</h2>
        <div className={s.subtitle}>
          {" "}
          Регламентирующие нормативно-правовые акты Азербайджано-Кыргызского
          Фонда развития.
        </div>
        <div className={s.documents}>
          {Array.isArray(reports) &&
            reports.map((it) => (
              <div className={s.documents_item}>
                <button
                  className={s.title}
                  onClick={(e) => openFileInNewWindow(e, it.file)}
                >
                  <FilePdfOutlined className={s.icon} /> &nbsp;{it?.name}
                </button>
                <div className={s.btn} onClick={() => downloadFile(it.id)}>
                  <DownloadOutlined />
                </div>
              </div>
            ))}
        </div>
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default Reports;
