import React, { useState } from "react";
import Header from "../../components/Header/Header";
import s from "./Requirements.module.scss";
import { FilePdfOutlined, DownloadOutlined } from "@ant-design/icons";
import Footer from "../../components/Footer/Footer";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";

const Requirements = () => {
  const { height, width } = useWindowDimensions();

  const downloadFile = (type) => {
    if (type === "documents_list") {
      const fileUrl = "/ПЕРЕЧЕНЬ ДОКУМЕНТОВ.pdf";
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = "Перечень_необходимых_документов.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    if (type === "financing") {
      const fileUrl = "/Инвестиционное кредитование.pdf";
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = "/Инвестиционное кредитование.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    if (type === "working_capital") {
      const fileUrl = "/Финансирование_оборотного_капитала.pdf";
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = "/Финансирование_оборотного_капитала.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    if (type === "business_plan") {
      const fileUrl = "/Форма бизнес-плана.pdf";
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = "Форма бизнес-плана.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const openFileInNewWindow = (e, type) => {
    e.preventDefault();
    if (type === "business_plan") {
      const fileUrl = "/Форма бизнес-плана.pdf";
      window.open(fileUrl, "_blank");
    }
    if (type === "documents_list") {
      const fileUrl = "/ПЕРЕЧЕНЬ ДОКУМЕНТОВ.pdf";
      window.open(fileUrl, "_blank");
    }
    if (type === "financing") {
      const fileUrl = "/Инвестиционное кредитование.pdf";
      window.open(fileUrl, "_blank");
    }
    if (type === "working_capital") {
      const fileUrl = "/Финансирование_оборотного_капитала.pdf";
      window.open(fileUrl, "_blank");
    }
  };

  return (
    <div className={s.reports_wrapper}>
      {width > 930 ? <Header /> : <MobileHeader />}
      <div className={s.reports}>
        <h2 className={s.h2}>Условия финансирования и необходимые документы</h2>
        {/* <div className={s.subtitle}> Регламентирующие нормативно-правовые акты Азербайджано-Кыргызского Фонда развития.</div> */}
        <div className={s.documents}>
          <div className={s.documents_item}>
            <button
              className={s.title}
              onClick={(e) => openFileInNewWindow(e, "financing")}
            >
              <FilePdfOutlined className={s.icon} /> &nbsp; Продукт:
              Инвестиционное кредитование
            </button>
            <div className={s.btn} onClick={() => downloadFile("financing")}>
              <DownloadOutlined />
            </div>
          </div>
          <div className={s.documents_item}>
            <button
              className={s.title}
              onClick={(e) => openFileInNewWindow(e, "working_capital")}
            >
              <FilePdfOutlined className={s.icon} /> &nbsp; Продукт:
              Финансирование оборотного капитала
            </button>
            <div
              className={s.btn}
              onClick={() => downloadFile("working_capital")}
            >
              <DownloadOutlined />
            </div>
          </div>

          <div className={s.documents_item}>
            <button
              className={s.title}
              onClick={(e) => openFileInNewWindow(e, "documents_list")}
            >
              <FilePdfOutlined className={s.icon} /> &nbsp; Перечень необходимых
              документов
            </button>
            <div
              className={s.btn}
              onClick={() => downloadFile("documents_list")}
            >
              <DownloadOutlined />
            </div>
          </div>

          <div className={s.documents_item}>
            <button
              className={s.title}
              onClick={(e) => openFileInNewWindow(e, "business_plan")}
            >
              <FilePdfOutlined className={s.icon} /> &nbsp; Форма бизнес плана
            </button>
            <div
              className={s.btn}
              onClick={() => downloadFile("business_plan")}
            >
              <DownloadOutlined />
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default Requirements;
