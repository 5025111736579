import React, { useState } from "react";
import s from "./Slider.module.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { SmallDashOutlined, HolderOutlined } from "@ant-design/icons";
import ApplicationDrawer from "../ApplicationDrawer/ApplicationDrawer";
import { Link } from "react-router-dom";
import { getNewsDetails } from "../../redux/ApplicationSlice";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../WindowDimensions/WindowDimensions";

const Slider = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const slider = useSelector((state) => state.application.slider);
  const { width } = useWindowDimensions();

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className={s.slider}>
      <Carousel
        showThumbs={false}
        infiniteLoop={true}
        autoPlay={true}
        showStatus={false}
        interval={7000}
      >
        {Array.isArray(slider) &&
          slider.map((it, index) => (
            <div className={s.slide} key={index}>
              <div className={s.content}>
                <div className={s.text}>
                  <div className={s.text_wrapper}>
                    <div className={s.title}>{it.title}</div>
                    {/* <div className={s.border}> </div> */}
                    <div className={s.subtitle}>{it.description}</div>
                    <Link
                      to={`/news/${it.news}`}
                      className={s.btns_wrapper}
                      onClick={() => dispatch(getNewsDetails(it?.news))}
                    >
                      <button className={s.btn}>Подробнее</button>
                    </Link>
                  </div>
                </div>
                <div className={s.img_wrapper}>
                  <img src={it.photo} className={s.img} />
                </div>
              </div>
            </div>
          ))}
      </Carousel>
      <ApplicationDrawer open={open} onClose={onClose} />
    </div>
  );
};

export default Slider;
