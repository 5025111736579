import React from "react";
import s from "./ManagementItem.module.scss";
import { IdcardOutlined } from "@ant-design/icons";
// import {kg} from '/icons8-кыргызстан-48.png'
// import {az} from '/icons8-азербайджан-48.png'

const ManagementItem = ({ data }) => {
  return (
    <div className={s.managementItem}>
      <img src={data.falg_icon} className={s.icon} />
      <div className={s.name}>
        <IdcardOutlined className={s.id_icon} /> &nbsp;{data.name}
      </div>
      <div className={s.line}></div>
      <div className={s.position}>{data.role}</div>
    </div>
  );
};

export default ManagementItem;
