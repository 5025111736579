import React from 'react'
import s from './ProjectItem.module.scss'



const ProjectItem = ({project}) => {
  return (
	<div className={s.projectItem}>
		<div className={s.img_wrapper} >
			<img src={project.img} className={s.img} />
		</div>
		<div className={s.text}>
			<div className={s.title}>{project.title}</div>
		<div className={s.header}>
			<div className={s.company}>{project.company}</div>
			<div className={s.date}>{project.date}</div>
		</div>

		<div className={s.category}>{project.category}</div>
		<div className={s.subtitle}>{project.subtitle}</div>
		</div>
		
		
	</div>
  )
}

export default ProjectItem