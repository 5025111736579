import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import s from "./Partners.module.scss";
import { Link } from "react-router-dom";
import Marquee from "react-fast-marquee";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Partners = () => {
  return (
    <div className={s.partners}>
      <h2 className={s.h2}>Наши партнеры</h2>

      <Marquee speed={30} pauseOnHover={true} className={s.marquee}>
        <Link to='https://www.ab.kg/ky' className={s.slide}>
          <img src='/assets/ayilbank.png' className={s.img} />
        </Link>
        <Link to='https://www.rsk.kg/' className={s.slide}>
          <img
            src='/assets/rsk.png'
            className={s.img}
            style={{ height: "40px", marginTop: "10px" }}
          />
        </Link>
        <Link to='https://kicb.net/kg/private/' className={s.slide}>
          <img src='/assets/kicb.png' className={s.img} />
        </Link>
        <Link to='https://halykbank.kg/' className={s.slide}>
          <img src='/assets/halyk.png' className={s.img} />
        </Link>
      </Marquee>
    </div>
  );
};

export default Partners;
