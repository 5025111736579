import React from "react";
import s from "./NewsCarousel.module.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import NewsCarouselItem from "../NewsCarouselItem/NewsCarouselItem";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNewsDetails } from "../../redux/ApplicationSlice";
import { EditOutlined, SnippetsOutlined } from "@ant-design/icons";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  largeDesktop: {
    breakpoint: { max: 3000, min: 2000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 2000, min: 1300 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1300, min: 1000 },
    items: 3,
  },
  tablet2: {
    breakpoint: { max: 1000, min: 650 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 650, min: 0 },
    items: 1,
  },
};

const NewsCarousel = () => {
  const news = useSelector((state) => state.application.news);
  const dispatch = useDispatch();
  return (
    <div className={s.wrapper}>
      {/* <h2 className={s.h2}>Новости</h2>
      <div className={s.subheader}>
        <EditOutlined />
        &nbsp; &nbsp;Следите за нашими новостями, чтобы быть в курсе событий
      </div> */}
      <div className={s.carousel_wrapper}>
        <Carousel responsive={responsive} autoPlay={false}>
          {Array.isArray(news) &&
            news.map((it) => (
              <Link
                to={`/news/${it.id}`}
                key={it.id}
                onClick={() => dispatch(getNewsDetails(it.id))}
              >
                <NewsCarouselItem data={it} />
              </Link>
            ))}
        </Carousel>
      </div>
    </div>
  );
};

export default NewsCarousel;
