import React from "react";
import s from "./PartnersPage.module.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";

const PartnersPage = () => {
  const { width } = useWindowDimensions();
  return (
    <div>
      {width > 930 ? <Header /> : <MobileHeader />}
      <h2 className={s.h2}>Наши партнеры</h2>
      <div className={s.content}>
        <Link to='https://www.ab.kg/ky' className={s.partnerItem}>
          <img src='/assets/ayilbank.png' className={s.icon} />
        </Link>
        <Link to='https://www.rsk.kg/' className={s.partnerItem}>
          <img
            src='/assets/rsk.png'
            className={s.icon}
            style={{ height: width > "950" ? "50px" : "30px" }}
          />
        </Link>
        <Link to='https://kicb.net/kg/private/' className={s.partnerItem}>
          <img src='/assets/kicb.png' className={s.icon} />
        </Link>
        <Link to='https://halykbank.kg/' className={s.partnerItem}>
          <img src='/assets/halyk.svg' className={s.icon} />
        </Link>
      </div>

      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default PartnersPage;
