import React from "react";
import s from "./Apprisers.module.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Table } from "antd";
import { Link } from "react-router-dom";
import { FilePdfOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getVacancyDetails } from "../../redux/ApplicationSlice";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";

const Apprisers = () => {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const apprisers = useSelector((state) => state.application.appraisers);

  const columns = [
    {
      title: "№",
      dataIndex: "number",
      width: 20,
      align: "center",
    },
    {
      title: "Независимые оценщики",
      dataIndex: `appriser`,
      width: 400,
    },
    {
      title: "Контакты",
      dataIndex: `contacts`,
      width: 600,
    },
  ];

  return (
    <div>
      {width > 930 ? <Header /> : <MobileHeader />}
      <div className={s.content}>
        <h2 className={s.h2}>Аккредитованные независимые оценщики</h2>
        <Table
          bordered
          pagination={false}
          columns={columns}
          dataSource={apprisers?.map((it, index) => ({
            number: `${index + 1}.`,
            appriser: it?.description,
            contacts: (
              <div>
                <div className={s.contactsItem}>
                  <div className={s.contactTitle}>Тел:</div>
                  <div>{it?.phone}</div>{" "}
                </div>
                <div className={s.contactsItem}>
                  <div className={s.contactTitle}>Aдрес:</div>
                  <div>{it?.address}</div>
                </div>
                <div className={s.contactsItem}>
                  <div className={s.contactTitle}>Email:</div>{" "}
                  <a href={`mailto:${it?.email}`} className={s.site}>
                    {it?.email}
                  </a>
                </div>
                {it.website ? (
                  <div className={s.contactsItem}>
                    <div className={s.contactTitle}>Cайт:</div>
                    <Link to={`${it?.website}`} className={s.site}>
                      {it?.website}
                    </Link>
                  </div>
                ) : null}
              </div>
            ),
          }))}
          className='appriser_table'
        />
      </div>

      <div className={s.documents_btns}>
        <Link
          to='/vacancies/2'
          className={s.title}
          onClick={() => dispatch(getVacancyDetails(2))}
        >
          <FilePdfOutlined className={s.icon} /> &nbsp; ОБЪЯВЛЕНИЕ О НАБОРЕ
          НЕЗАВИСИМЫХ ОЦЕНЩИКОВ
        </Link>
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default Apprisers;
