import React from "react";
import Header from "../../components/Header/Header";
import s from "./Vacancies.module.scss";
import VacanciesItem from "../../components/VacanciesItem/VacanciesItem";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getVacancyDetails } from "../../redux/ApplicationSlice";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";

const data = [
  {
    id: 1,
    title: "Консультант по отраслям",
    description:
      "Азербайджано-Кыргызский Фонд развития объявляет о проведении процедуры аккредитации независимых консультантов по отраслям и приглашает юридические или физические лица по оказанию услуг подавать заявки на участие в объявляемой процедуре аккредитации. Предложения необходимо направлять на электронную почту azkgdf@gmail.com.",
    date: " ",
    deadline: "",
    location: "",
    salary: "",
  },
  // {
  // 	id: 2,
  // 	title: 'Открытый тендер на отбор главных бухгалтеров в проекты, финансируемые Фондом',
  // 	description: 'РКФР для создания резерва кадров объявляет открытый тендер на отбор главных бухгалтеров (финансовых контролеров) в проекты, финансируемые Фондом.',
  // 	date: '5.03.2024',
  // 	location: 'удаленно',
  // 	img: '/assets/10.jpeg',
  // 	salary: 'договорная'
  // },
  // {	id: 3,
  // 	title: 'Риелтор для реализации объектов недвижимости',
  // 	description: 'РКФР объявляет открытый конкурс на отбор риелтора для реализации объектов недвижимости, расположенных в г. Бишкек, г. Ош, а также Ошской, Жалал-Абадской, Баткенской и Чуйской областях.',
  // 	date: '15.03.2024',
  // 	location: 'частичная занятость',
  // 	img: '/assets/12.png',
  // 	salary: 'от 30 000 до 60 000 сом'
  // },{
  // 	id: 4,
  // 	title: 'Консультант по недвижимости-риелтор ',
  // 	description: 'РКФР объявляет открытый конкурс на отбор риелтора для реализации объектов недвижимости, расположенных в г. Бишкек, г. Ош, а также Ошской, Жалал-Абадской, Баткенской и Чуйской областях.',
  // 	date: ' 17.11.2023',
  // 	deadline: ' 23.11.2023',
  // 	location: 'г.Бишкек',
  // 	salary: 'сдельная'
  // },
  // {
  // 	id: 5,
  // 	title: 'Открытый тендер на отбор главных бухгалтеров в проекты, финансируемые Фондом',
  // 	description: 'РКФР для создания резерва кадров объявляет открытый тендер на отбор главных бухгалтеров (финансовых контролеров) в проекты, финансируемые Фондом.',
  // 	date: '5.03.2024',
  // 	location: 'г.Бишкек',
  // 	img: '/assets/10.jpeg',
  // 	salary: 'от 30 000 до 60 000 сом'
  // },
  // {
  // 	id: 6,
  // 	title: 'Риелтор для реализации объектов недвижимости',
  // 	description: 'РКФР объявляет открытый конкурс на отбор риелтора для реализации объектов недвижимости, расположенных в г. Бишкек, г. Ош, а также Ошской, Жалал-Абадской, Баткенской и Чуйской областях.',
  // 	date: '15.03.2024',
  // 	location: 'г.Бишкек',
  // 	img: '/assets/12.png',
  // 	salary: 'от 30 000 до 60 000 сом'
  // }
];

const Vacancies = () => {
  const dispatch = useDispatch();
  const vacancies = useSelector((state) => state.application.vacancies);
  const { width } = useWindowDimensions();
  const filteredVacancies = vacancies?.filter(
    (it) => it.id !== 1 && it.id !== 2
  );

  return (
    <div className={s.vacancies}>
      {width > 930 ? <Header /> : <MobileHeader />}
      <div className={s.wrapper}>
        {Array.isArray(filteredVacancies) && filteredVacancies.length > 0 ? (
          <h2 className={s.h2}>Вакансии</h2>
        ) : null}
        <div className={s.vacancy_list}>
          {Array.isArray(filteredVacancies) && filteredVacancies.length > 0 ? (
            filteredVacancies?.map((it) => (
              <Link
                to={`/vacancies/${it.id}`}
                onClick={() => dispatch(getVacancyDetails(it.id))}
              >
                <VacanciesItem data={it} />
              </Link>
            ))
          ) : (
            <div className={s.noVacancy}>Пока нет актуальных вакансий...</div>
          )}
        </div>
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default Vacancies;
