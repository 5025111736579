import React from "react";
import s from "./Map.module.scss";

const Map = () => {
  return (
    <div className={s.map}>
      <h2 className={s.h2} data-aos='fade-up' data-aos-duration='2000'>
        Как нас найти
      </h2>
      <iframe
        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2923.894189090213!2d74.59550518522798!3d42.875077428034324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x389eb7dc14ca922d%3A0x113e86c78241f66d!2zMTc4INGD0LsuINCf0LDQvdGE0LjQu9C-0LLQsCwg0JHQuNGI0LrQtdC6!5e0!3m2!1sru!2skg!4v1713516181048!5m2!1sru!2skg'
        width='100%'
        height='400'
        style={{ borderTop: "5px solid white" }}
        allowFullScreen=''
        loading='lazy'
        referrerPolicy='no-referrer-when-downgrade'
      ></iframe>
    </div>
  );
};

export default Map;
