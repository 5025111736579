import React from "react";
import s from "./TeamItem.module.scss";
import {
  DownloadOutlined,
  FileSearchOutlined,
  IdcardOutlined,
} from "@ant-design/icons";

const TeamItem = ({ data, index }) => {
  const openFileInNewWindow = (e, file) => {
    e.preventDefault();
    const fileUrl = file;
    window.open(fileUrl, "_blank");
  };
  return (
    <div className={s.team_item}>
      <div className={s.img_wrapper}>
        <img
          src={data.photo ? data.photo : "/assets/noImage.png"}
          className={s.img}
        ></img>
        <div className={s.divider}></div>
      </div>

      <div className={s.divider2}></div>
      <div className={s.team_text}>
        <div className={s.position}>
          {" "}
          <IdcardOutlined className={s.icon} /> &nbsp;{data?.role}
        </div>

        <div className={s.name}>{data.name ? data?.name : null}</div>
        {data.resume ? (
          <div
            className={s.cv}
            onClick={(e) => openFileInNewWindow(e, data.resume)}
          >
            <FileSearchOutlined className={s.icon} /> Резюме
          </div>
        ) : null}
        <div className={s.description}>{data.description}</div>
      </div>
    </div>
  );
};

export default TeamItem;
