import React from "react";
import s from "./VacanciesItem.module.scss";
import { EnvironmentOutlined, FieldTimeOutlined } from "@ant-design/icons";

const VacanciesItem = ({ data }) => {
  return (
    <div className={s.item}>
      <div className={s.title}>{data.title}</div>
      <div className={s.divider}></div>
      <div className={s.description}>{data.description}</div>
    </div>
  );
};

export default VacanciesItem;
