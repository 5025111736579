import React, { useState } from "react";
import s from "./FeedbackForm.module.scss";
import "../../styles/style.css";
import { Form, Input, Row, Col, FormItem, Select, message } from "antd";
import useWindowDimensions from "../WindowDimensions/WindowDimensions";
import { useDispatch } from "react-redux";
import { sendFeedback } from "../../redux/ApplicationSlice";
const { TextArea } = Input;
const { Option } = Select;

const FeedbackForm = () => {
  const [form] = Form.useForm();
  const { height, width } = useWindowDimensions();
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [subject, setSubject] = useState();
  const [clientMessage, setClientMessage] = useState();

  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Вы отправили сообщение!",
    });
  };

  const getFeedback = async () => {
    try {
      form.validateFields();
      let re = /\S+@\S+\.\S+/;
      if (
        name &&
        phone &&
        email &&
        subject &&
        clientMessage &&
        re.test(email)
      ) {
        const res = await dispatch(
          sendFeedback({
            full_name: name,
            phone,
            email,
            subject,
            message: clientMessage,
          })
        );

        if (res.meta.requestStatus === "fulfilled") {
          success();
          form.resetFields();
          setName("");
          setPhone("");
          setEmail("");
          setClientMessage("");
          setSubject("");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={s.feedback}>
      {contextHolder}
      <div className={s.wrapper}>
        <h2 className={s.title}>Связаться с нами</h2>

        <Form className={s.form} layout='vertical' form={form}>
          <Row gutter={24}>
            <Col span={width > 700 ? 12 : 24}>
              <Form.Item
                name='username'
                label='ФИО'
                rules={[
                  {
                    required: true,
                    message: "Укажите ваше ФИО",
                  },
                ]}
              >
                <Input
                  className={s.input}
                  placeholder='Ваше имя'
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={width > 700 ? 12 : 24}>
              <Form.Item
                label='Телефон'
                name='phone'
                rules={[
                  {
                    required: true,
                    message: "Укажите ваш номер телефона",
                  },
                ]}
              >
                <Input
                  className={s.input}
                  placeholder='Ваш номер телефона'
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={width > 700 ? 12 : 24}>
              <Form.Item
                label='Email'
                name='email'
                rules={[
                  { required: true, message: "Укажите ваш email" },
                  { type: "email", message: "Укажите корректный email" },
                ]}
              >
                <Input
                  className={s.input}
                  placeholder='Ваш электронный адрес'
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={width > 700 ? 12 : 24}>
              <Form.Item
                label='Тема'
                name='subject'
                rules={[
                  {
                    required: true,
                    message: "Укажите тему вашего сообщения",
                  },
                ]}
              >
                <Input
                  className={s.input}
                  placeholder='Укажите тему вашего сообщения'
                  onChange={(e) => setSubject(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label='Ваше сообщение'
                name='message'
                rules={[
                  {
                    required: true,
                    message: "Напишите сообщение",
                  },
                ]}
              >
                <TextArea
                  className={s.input}
                  style={{ minHeight: "100px" }}
                  placeholder='Напишите нам ... '
                  onChange={(e) => setClientMessage(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <button className={s.btn} onClick={(e) => getFeedback()}>
            Отправить
          </button>
        </Form>
      </div>
    </div>
  );
};

export default FeedbackForm;
