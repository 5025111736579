import React from 'react'
import s from './NewsCarouselItem.module.scss'

const NewsCarouselItem = ({data}) => {
  return (
	<div className={s.item}>
		<div className={s.img_wrapper}>
			<img src={data.image} alt='' className={s.img}/>
		</div>
		<div className={s.title}>{data.title}</div>
		<div className={s.description} dangerouslySetInnerHTML={{__html: data?.body}} style={{lineHeight: 1.5, color: 'gray'}}/>
			<div className={s.date}>{data.created_at}</div>
		
		<button className={s.btn}>Подробнее</button>
	</div>
  )
}

export default NewsCarouselItem