import React from "react";
import s from "./Footer.module.scss";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import {
  PhoneOutlined,
  MailOutlined,
  EnvironmentOutlined,
  HomeOutlined,
  AuditOutlined,
  FileProtectOutlined,
  GlobalOutlined,
  WhatsAppOutlined,
  FacebookOutlined,
  InstagramOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";

const Footer = () => {
  const { height } = useWindowDimensions();
  const contacts = useSelector((state) => state.application.contacts);

  return (
    <div className={s.footer}>
      <div className={s.wrapper}>
        <div className={s.logo}>
          <div>
            <div className={s.logo_wrapper}>
              <img src='/dark_logo.png' className={s.footer_logo}></img>
            </div>
            <div className={s.social_networks}>
              {/* <Link to={contacts.whatsapp}>
                <WhatsAppOutlined className={s.icon} />
              </Link> */}
              <Link to={contacts?.instagram}>
                <InstagramOutlined className={s.icon} />
              </Link>
              <Link to={contacts?.facebook}>
                <FacebookOutlined className={s.icon} />
              </Link>
            </div>
          </div>
        </div>
        <div className={s.item}>
          <div className={s.title}>
            Контакты
            <div className={s.line}></div>
          </div>
          <div className={s.contacts}>
            {contacts?.phone_first ? (
              <div className={s.phone}>
                <PhoneOutlined className={s.contacts_icon} /> &nbsp;{" "}
                {contacts?.phone_first}
              </div>
            ) : null}
            {contacts?.email ? (
              <div className={s.email}>
                <MailOutlined className={s.contacts_icon} /> &nbsp;{" "}
                <a href={`mailto:${contacts?.email}`}>{contacts?.email}</a>
              </div>
            ) : null}
            <div className={s.address}>
              <EnvironmentOutlined className={s.contacts_icon} />
              &nbsp;&nbsp;&nbsp;{contacts?.address}
            </div>
          </div>
        </div>
        <div className={s.item}>
          <div className={s.title}>Полезные ссылки</div>
          <div className={s.line}></div>
          <div className={s.contacts}>
            <div className={s.phone}>
              <Link to='/about'>
                <HomeOutlined className={s.link_icon} /> &nbsp; О нас
              </Link>
            </div>
            <div className={s.phone}>
              <Link to='/approvedProjects'>
                <FileProtectOutlined /> &nbsp; Проекты
              </Link>
            </div>
            <div className={s.email}>
              <Link to='/tenders'>
                <AuditOutlined /> &nbsp; Тендеры
              </Link>
            </div>
            <div className={s.address}>
              <Link to='/news'>
                <GlobalOutlined /> &nbsp; Новости
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={s.bottom}>© Азербайджано-Кыргызский Фонд развития. </div>
    </div>
  );
};

export default Footer;
