import React from "react";
import s from "./Tenders.module.scss";
import Header from "../../components/Header/Header";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getTenderDetails } from "../../redux/ApplicationSlice";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import { Table, Tabs, Tag } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  IssuesCloseOutlined,
} from "@ant-design/icons";

import MobileTenderTable from "../../components/MobileTenderTable/MobileTenderTable";

const Tenders = () => {
  const dispatch = useDispatch();
  const tenders = useSelector((state) => state.application.tenders);
  const { width } = useWindowDimensions();

  const onChange = (key) => {
    console.log(key);
  };

  const columns = [
    {
      title: "№",
      dataIndex: "number",
    },
    {
      title: "Наименование ",
      dataIndex: `tender_name`,
      width: 350,
    },
    {
      title: "№ тендера",
      dataIndex: `tender_number`,
      width: 125,
      align: "center",
    },
    {
      title: "Категория",
      dataIndex: `category`,
    },
    {
      title: `Опубликовано`,
      dataIndex: "published",
    },
    {
      title: "Крайний срок подачи",
      dataIndex: `deadline`,
    },
    {
      title: "Тип объявления",
      dataIndex: `type`,
    },
    {
      title: "Статус",
      dataIndex: `status`,
    },
  ];

  return (
    <div>
      {width > 930 ? <Header /> : <MobileHeader />}

      {Array.isArray(tenders) && tenders.length > 0 ? (
        <h2 className={s.h2}>Тендеры</h2>
      ) : null}

      {width > 930 ? (
        <div className={s.tenders_list}>
          <Tabs onChange={onChange} defaultActiveKey='1' type='card'>
            <TabPane tab='Актуальные' key='1'>
              <Table
                bordered
                pagination={false}
                columns={columns}
                className={s.tenders_table}
                dataSource={
                  Array.isArray(tenders) &&
                  tenders
                    .filter((el) => el.status === "Актуальный")
                    .map((it, index) => ({
                      number: <div className={s.td}>{index + 1}.</div>,
                      tender_name: (
                        <Link
                          to={`/tenders/${it.id}`}
                          className={s.name}
                          onClick={() => dispatch(getTenderDetails(it.id))}
                        >
                          {it?.title}
                        </Link>
                      ),
                      tender_number: it?.tender_no,
                      category: it?.category?.name,
                      published: it?.published_date,
                      deadline: it?.deadline,
                      type: it?.announcement_type,
                      status: (
                        <div>
                          <Tag color='#7eca8f'>
                            <CheckCircleOutlined />
                            &nbsp;Актуальный
                          </Tag>
                        </div>
                      ),
                    }))
                }
              />
            </TabPane>
            <TabPane tab='Итоги' key='2'>
              <Table
                bordered
                pagination={false}
                columns={columns}
                dataSource={
                  Array.isArray(tenders) &&
                  tenders
                    .filter((el) => el.status === "Итоги")
                    .map((it, index) => ({
                      number: <div className={s.td}>{index + 1}</div>,
                      tender_name: (
                        <Link
                          to={`/tenders/${it.id}`}
                          className={s.name}
                          onClick={() => dispatch(getTenderDetails(it.id))}
                        >
                          {it?.title}
                        </Link>
                      ),
                      tender_number: it?.tender_no,
                      category: it?.category?.name,
                      published: it?.published_date,
                      deadline: it?.deadline,
                      type: it?.announcement_type,
                      status: (
                        <div>
                          <Tag color='rgba(239, 91, 91, 0.927)'>
                            <ExclamationCircleOutlined />
                            &nbsp;Итоги
                          </Tag>
                        </div>
                      ),
                    }))
                }
              />
            </TabPane>
            <TabPane tab='Завершенные' key='3'>
              <Table
                bordered
                pagination={false}
                columns={columns}
                className={s.tenders_table}
                dataSource={
                  Array.isArray(tenders) &&
                  tenders
                    .filter((el) => el.status === "Завершен")
                    .map((it, index) => ({
                      number: <div className={s.td}>{index + 1}</div>,
                      tender_name: (
                        <Link
                          to={`/tenders/${it.id}`}
                          className={s.name}
                          onClick={() => dispatch(getTenderDetails(it.id))}
                        >
                          {it?.title}
                        </Link>
                      ),
                      tender_number: it?.tender_no,
                      category: it?.category?.name,
                      published: it?.published_date,
                      deadline: it?.deadline,
                      type: it?.announcement_type,
                      status: (
                        <div>
                          <Tag color='#82c9d5'>
                            <IssuesCloseOutlined />
                            &nbsp;Завершен
                          </Tag>
                        </div>
                      ),
                    }))
                }
              />
            </TabPane>
          </Tabs>
        </div>
      ) : (
        <MobileTenderTable />
      )}
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default Tenders;
