import React from "react";
import Header from "../../components/Header/Header";
import s from "./AboutUs.module.scss";
import Footer from "../../components/Footer/Footer";
import Mission from "../../components/Mission/Mission";
import {
  HomeOutlined,
  FilterOutlined,
  ToolOutlined,
  PullRequestOutlined,
  AlertOutlined,
  TruckOutlined,
  BgColorsOutlined,
  ExperimentOutlined,
  SkinOutlined,
  GoldOutlined,
  CarOutlined,
  ShopOutlined,
  ApartmentOutlined,
  BranchesOutlined,
  LaptopOutlined,
} from "@ant-design/icons";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";

const AboutUs = () => {
  const { width } = useWindowDimensions();
  return (
    <div>
      {width > 930 ? <Header /> : <MobileHeader />}
      <div className={s.about_content}>
        <div className={s.text}>
          <h2 className={s.title}>О Фонде</h2>
          <div className={s.description}>
            Азербайджано-Кыргызский Фонд развития учрежден и действует в
            соответствии с Соглашением между Правительством Азербайджанской
            Республики и Кабинетом Министров Кыргызской Республики от 11 октября
            2022 года в целях содействия модернизации и развитию экономики,
            экономического сотрудничества между Кыргызской Республикой и
            Азербайджанской Республикой, эффективного использования двусторонних
            возможностей. Фонд взаимодействует с международными организациями,
            корпорациями и институтами развития в целях реализации проектов на
            территории Кыргызской Республики.
          </div>

          <div className={s.mission}>
            <div className={s.mission_wrapper}>
              <h3 className={s.h3}> Миссия, цели, задачи</h3>
              <div className={s.mission_text}>
                <Mission />
              </div>
            </div>
            <div className={s.mission_wrapper}>
              <h3 className={s.h3}> Направления и принципы деятельности</h3>
              <div className={s.introduction}>
                Фонд осуществляет инвестиции в приоритетные для учредителей
                проекты в Кыргызской Республике путем предоставления кредитов и
                займов, участия в капитале и использования иных финансовых
                инструментов в отношениях с хозяйствующими субъектами,
                зарегистрированными и ведущими свою деятельность на территории
                Кыргызской Республики, в том числе в следующих областях:
              </div>
              <div className={s.mission_text}>
                <div className={s.text_item}>
                  <div>
                    <HomeOutlined className={s.icon} /> &nbsp;
                  </div>
                  <div>агропромышленный комплекс;</div>
                </div>
                <div className={s.text_item}>
                  <div>
                    <FilterOutlined className={s.icon} />
                    &nbsp;
                  </div>
                  <div>сектор водоснабжения;</div>
                </div>
                <div className={s.text_item}>
                  <div>
                    <AlertOutlined className={s.icon} /> &nbsp;
                  </div>
                  <div>сектор здравоохранения;</div>
                </div>
                <div className={s.text_item}>
                  <div>
                    <TruckOutlined className={s.icon} /> &nbsp;
                  </div>
                  <div>автомобильная промышленность;</div>
                </div>
                <div className={s.text_item}>
                  <div>
                    <BgColorsOutlined className={s.icon} /> &nbsp;
                  </div>
                  <div> наука, исследования и технология;</div>
                </div>
                <div className={s.text_item}>
                  <div>
                    <PullRequestOutlined className={s.icon} /> &nbsp;
                  </div>
                  <div> газопромышленный комплекс;</div>
                </div>
                <div className={s.text_item}>
                  <div>
                    <ExperimentOutlined className={s.icon} /> &nbsp;
                  </div>
                  <div> химическая и нефтехимическая промышленность;</div>
                </div>
                <div className={s.text_item}>
                  <div>
                    <SkinOutlined className={s.icon} /> &nbsp;
                  </div>
                  <div> швейная и текстильная промышленность;</div>
                </div>
                <div className={s.text_item}>
                  <div>
                    <ToolOutlined className={s.icon} /> &nbsp;
                  </div>
                  <div> обрабатывающая промышленность;</div>
                </div>
                <div className={s.text_item}>
                  <div>
                    <GoldOutlined className={s.icon} /> &nbsp;
                  </div>
                  <div>горнодобывающая и металлургическая промышленность;</div>
                </div>
                <div className={s.text_item}>
                  <div>
                    <CarOutlined className={s.icon} /> &nbsp;
                  </div>
                  <div> транспорт;</div>
                </div>
                <div className={s.text_item}>
                  <div>
                    <HomeOutlined className={s.icon} /> &nbsp;
                  </div>
                  <div> жилищное строительство;</div>
                </div>
                <div className={s.text_item}>
                  <div>
                    <ShopOutlined className={s.icon} /> &nbsp;
                  </div>
                  <div> торговля;</div>
                </div>
                <div className={s.text_item}>
                  <div>
                    <ApartmentOutlined className={s.icon} /> &nbsp;
                  </div>
                  <div> развитие инфраструктуры;</div>
                </div>
                <div className={s.text_item}>
                  <div>
                    <LaptopOutlined className={s.icon} /> &nbsp;
                  </div>
                  <div> информационные технологии;</div>
                </div>
                <div className={s.text_item}>
                  <div>
                    <BranchesOutlined className={s.icon} /> &nbsp;
                  </div>
                  <div> развитие туризма.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default AboutUs;
