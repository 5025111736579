import React, { useState } from "react";
import s from "./Application.module.scss";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
  Upload,
  Tooltip,
  Checkbox,
} from "antd";
import useWindowDimensions from "../WindowDimensions/WindowDimensions";
import { sendApplication } from "../../redux/ApplicationSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  BankFilled,
  CalendarFilled,
  ContactsFilled,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Loading from "../Loading/Loading";

const { Option } = Select;
const { TextArea } = Input;

const props = {
  name: "file",
  action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  progress: {
    strokeColor: {
      "0%": "#108ee9",
      "100%": "#87d068",
    },
    strokeWidth: 3,
    format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
  },
};

const ApplicationDrawer = ({ open, onClose }) => {
  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const loading = useSelector((state) => state.application.loading);
  const [isChecked, setIsChecked] = useState(false);

  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [file, setFile] = useState([]);

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Вы отправили информацию о проекте!",
    });
  };

  const sendForm = async (values) => {
    const data = new FormData();

    data.append("full_name", values.name);
    data.append("company", values.companyName);
    data.append("phone", values.phone);
    data.append("email", values.email);
    data.append("project_goal", values.projectGoal);
    data.append("project_description", values.projectDescription);
    data.append("industry.industry", values.projectField);
    data.append("total_cost", values.projectValue);
    data.append("intended_use", values.intendedUse);
    data.append("existing_business_description", values.businessDescription);
    data.append("requested_amount", values.requestedAmount);
    data.append("pledge", values.pledge);
    data.append("existing_loans", values.currentLoans);

    if (file.fileList && file.fileList.length > 0) {
      file.fileList.forEach((fileItem) => {
        data.append("accompanying_documents", fileItem.originFileObj);
      });
    }

    const res = await dispatch(sendApplication(data));

    if (res.type === "application/sendApplication/fulfilled") {
      success();
      form.resetFields();
      setFile([]);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {contextHolder}
      <Drawer
        title='ПРЕДВАРИТЕЛЬНАЯ ИНФОРМАЦИЯ О ПРОЕКТЕ'
        width={width > 1000 ? "90%" : "100%"}
        onClose={onClose}
        open={open}
      >
        <div className={s.intro}>
          <strong>Обязательство по заполнению предварительной заявки:</strong>{" "}
          <br />
          Я, представитель юридического лица, настоящим подтверждаю, что все
          данные, внесённые в форму предварительной заявки, являются
          достоверными, а также осознаю, что предоставление неполной информации
          может привести к отказу в финансировании.
        </div>
        <Form
          form={form}
          layout='vertical'
          className={s.form}
          encType='multipart/form-data'
          onFinish={sendForm}
        >
          <div className={s.section}>
            <h3 className={s.h2}>
              <ContactsFilled className={s.icon} /> О заявителе
            </h3>
            <Row gutter={24}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='companyName'
                  label={
                    <div>
                      Название компании{" "}
                      <Tooltip
                        placement='top'
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              Пример заполнения:
                            </div>
                            <div className={s.tooltipContent}>
                              ОсОО «Молочный рай», ИНН 00903201210186, г. Бишкек
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  className={s.formItem}
                  rules={[
                    {
                      required: true,
                      message: "Укажите название вашей компании",
                    },
                  ]}
                >
                  <Input
                    className={s.form_input}
                    placeholder='Укажите название вашей компании'
                  />
                </Form.Item>
              </Col>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='name'
                  label={
                    <div>
                      ФИО (контактное лицо и должность){" "}
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              Пример заполнения:
                            </div>
                            <div className={s.tooltipContent}>
                              Султанов Нурлан, генеральный директор
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Укажите полностью ваше ФИО",
                    },
                  ]}
                >
                  <Input
                    className={s.form_input}
                    placeholder='Укажите полностью ваше ФИО'
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='phone'
                  label={
                    <div>
                      Контактный телефон
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              Пример заполнения:
                            </div>
                            <div className={s.tooltipContent}>
                              Моб.: + 996 (500) 888-222; Раб.: + 996 (312)
                              888-222
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Укажите актуальный номер телефона",
                    },
                  ]}
                >
                  <Input
                    className={s.form_input}
                    placeholder='Укажите актуальный номер телефона'
                  />
                </Form.Item>
              </Col>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='email'
                  label={
                    <div>
                      Электронная почта
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              Пример заполнения:
                            </div>
                            <div className={s.tooltipContent}>
                              moloko@gmail.com
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  rules={[
                    { required: true, message: "Укажите ваш email" },
                    { type: "email", message: "Укажите корректный email" },
                  ]}
                >
                  <Input
                    className={s.form_input}
                    placeholder='Укажите ваш email'
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className={s.section}>
            <h3 className={s.h2}>
              <CalendarFilled className={s.icon} /> О проекте
            </h3>
            <Row gutter={24}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='projectGoal'
                  label={
                    <div>
                      Цель проекта{" "}
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              Пример заполнения:
                            </div>
                            <div className={s.tooltipContent}>
                              Увеличение объёма производства молочной продукции
                              путем расширения цеха и приобретения оборудования
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  className={s.formItem}
                  rules={[
                    {
                      required: true,
                      message: "Укажите цель проекта",
                    },
                  ]}
                >
                  <TextArea
                    className={s.form_input}
                    placeholder='Укажите цель проекта'
                    style={{ height: "100px" }}
                  />
                </Form.Item>
              </Col>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='projectDescription'
                  label={
                    <div>
                      Описание проекта{" "}
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              Пример заполнения:
                            </div>
                            <div className={s.tooltipContent}>
                              Проект по переработке молока и молочной продукции
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  className={s.formItem}
                  rules={[
                    {
                      required: true,
                      message: "Укажите краткое описание проекта",
                    },
                  ]}
                >
                  <TextArea
                    className={s.form_input}
                    style={{ height: "100px" }}
                    placeholder='Укажите краткое описание проекта'
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='projectField'
                  label={
                    <div>
                      Отрасль проекта{" "}
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              Пример заполнения:
                            </div>
                            <div className={s.tooltipContent}>
                              агропромышленный комплекс
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Выберите отрасль проекта",
                    },
                  ]}
                >
                  <Select
                    placeholder='Выберите отрасль проекта'
                    className={s.form_input}
                    style={{ padding: "0", borderRadius: "10px" }}
                  >
                    <Option value='агропромышленный комплекс'>
                      агропромышленный комплекс
                    </Option>
                    <Option value='сектор водоснабжения'>
                      сектор водоснабжения
                    </Option>
                    <Option value='сектор здравоохранения'>
                      сектор здравоохранения
                    </Option>
                    <Option value='автомобильная промышленность'>
                      автомобильная промышленность
                    </Option>
                    <Option value='наука, исследования и технология'>
                      наука, исследования и технология
                    </Option>
                    <Option value='газопромышленный комплекс'>
                      газопромышленный комплекс
                    </Option>
                    <Option value='химическая и нефтехимическая промышленность'>
                      химическая и нефтехимическая промышленность
                    </Option>
                    <Option value='швейная и текстильная промышленность'>
                      швейная и текстильная промышленность
                    </Option>
                    <Option value='обрабатывающая промышленность'>
                      обрабатывающая промышленность
                    </Option>
                    <Option value='горнодобывающая и металлургическая промышленность'>
                      горнодобывающая и металлургическая промышленность
                    </Option>
                    <Option value='транспорт'>транспорт</Option>
                    <Option value='жилищное строительство'>
                      жилищное строительство
                    </Option>
                    <Option value='торговля'>торговля</Option>
                    <Option value='развитие инфраструктуры'>
                      развитие инфраструктуры
                    </Option>
                    <Option value='информационные технологии'>
                      информационные технологии
                    </Option>
                    <Option value='развитие туризма'>развитие туризма</Option>
                    <Option value='прочие'>прочие</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='projectValue'
                  label={
                    <div>
                      Общая сумма проекта, $
                      <Tooltip
                        placement='topLeft'
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              Пример заполнения:
                            </div>
                            <div className={s.tooltipContent}>650 000 USD</div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Укажите общую сумму проекта и валюту",
                    },
                  ]}
                >
                  <Input
                    className={s.form_input}
                    placeholder='Укажите общую сумму проекта и валюту'
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='intendedUse'
                  className={s.formItem}
                  label={
                    <div>
                      Целевое использование (детальная информация по статьям
                      затрат проекта){" "}
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        arrow={false}
                        placement='topLeft'
                        style={{
                          width: width > 600 ? "400px" : "320px",
                        }}
                        height={450}
                        width={320}
                        title={
                          <div
                            className={s.tooltip}
                            style={{
                              maxWidth: "400px",
                              width: width > 600 ? "400px" : "240px",
                            }}
                          >
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              Пример заполнения:
                            </div>
                            <div className={s.tooltipContent}>
                              <table className={s.table}>
                                <tr>
                                  <th style={{ width: "20px" }}>№</th>
                                  <th
                                    style={{
                                      width: width > 600 ? "250px" : "125px",
                                    }}
                                  >
                                    Наименование
                                  </th>
                                  <th>Cумма</th>
                                </tr>
                                <tbody>
                                  <tr>
                                    <td>1. </td>
                                    <td>Приобретение оборудования </td>
                                    <td>250 000 USD</td>
                                  </tr>
                                  <tr>
                                    <td>2.</td>
                                    <td>Приобретение спец. техники </td>
                                    <td>70 000 USD</td>
                                  </tr>
                                  <tr>
                                    <td>3.</td>
                                    <td>Строительно-монтажные работы </td>
                                    <td>330 000 USD</td>
                                  </tr>
                                  <tr>
                                    <td>4.</td>
                                    <td>…….</td>
                                    <td>…….</td>
                                  </tr>
                                  <tr>
                                    <td></td>
                                    <th>Итого</th>
                                    <th>650 000 USD</th>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Укажите целевое использование",
                    },
                  ]}
                >
                  <TextArea
                    className={s.form_input}
                    style={{ height: "200px" }}
                    placeholder='Укажите детальную информацию по статьям затрат проекта'
                  />
                </Form.Item>
              </Col>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='businessDescription'
                  label={
                    <div>
                      Описание действующего бизнеса
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        placement='topLeft'
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              Пример заполнения:
                            </div>
                            <div className={s.tooltipContent}>
                              Цех по переработке молочной продукции{" "}
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  className={s.formItem}
                  rules={[
                    {
                      required: true,
                      message: "Опишите ваш действующий бизнес",
                    },
                  ]}
                >
                  <TextArea
                    style={{ marginTop: "28px", height: "200px" }}
                    className={s.form_input}
                    placeholder='Опишите ваш действующий бизнес'
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className={s.section}>
            <h3 className={s.h2}>
              <BankFilled className={s.icon} /> О займе
            </h3>
            <Row gutter={24}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='requestedAmount'
                  className={s.formItem}
                  label={
                    <div>
                      Запрашиваемая сумма в АКФР{" "}
                      <Tooltip
                        placement='topLeft'
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              Пример заполнения:
                            </div>
                            <div className={s.tooltipContent}>
                              500 000 USD, запрашиваемая сумма не должна
                              превышать 70% от стоимости проекта
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Укажите запрашиваемую сумму",
                    },
                  ]}
                >
                  <TextArea
                    style={{
                      minHeight: "100px",

                      verticalAlign: "top",
                      height: "100px",
                    }}
                    className={s.form_input}
                    placeholder='Укажите запрашиваемую сумму'
                  />
                </Form.Item>
              </Col>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='currentLoans'
                  className={s.formItem}
                  rules={[
                    {
                      required: true,
                      message:
                        "Укажите действующие кредиты на момент подачи информации",
                    },
                  ]}
                  label={
                    <div>
                      Действующие кредиты на момент подачи информации
                      <Tooltip
                        placement='topRight'
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              Пример заполнения №1:
                            </div>
                            <div className={s.tooltipContent}>
                              Получен кредит в размере 100 000 USD в
                              коммерческом банке для запуска производства
                              молочных продуктов. <br />
                              <div>
                                <div
                                  className={s.tooltipTitle}
                                  style={{ marginTop: "5px" }}
                                >
                                  <ExclamationCircleOutlined
                                    className={s.tooltip_text_icon}
                                  />
                                  Пример заполнения №2:
                                </div>
                                <div className={s.tooltipContent}>
                                  Действующих кредитов нет.
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                >
                  <TextArea
                    className={s.form_input}
                    style={{ height: "100px" }}
                    placeholder='Укажите действующие кредиты на момент подачи информации'
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='pledge'
                  label={
                    <div>
                      Полная информация о Залоге
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        placement='topLeft'
                        title={
                          <div
                            className={s.tooltip}
                            style={{
                              maxWidth: "400px",
                              width: width > 600 ? "400px" : "240px",
                            }}
                          >
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              Пример заполнения:
                            </div>
                            <div className={s.tooltipContent}>
                              <table className={s.table}>
                                <tbody>
                                  <tr>
                                    <td style={{ width: "20px" }}>1. </td>
                                    <td
                                      style={{
                                        width: width > 600 ? "200px" : "120px",
                                      }}
                                    >
                                      Нежилое помещение
                                    </td>
                                    <td>400 000 USD</td>
                                  </tr>
                                  <tr>
                                    <td>2.</td>
                                    <td>Жилой дом </td>
                                    <td>50 000 USD</td>
                                  </tr>
                                  <tr>
                                    <td>3.</td>
                                    <td>Квартира </td>
                                    <td>70 000 USD</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  className={s.formItem}
                  rules={[
                    {
                      required: true,
                      message: "Опишите залог",
                    },
                  ]}
                >
                  <TextArea
                    className={s.form_input}
                    style={{ height: "200px" }}
                    placeholder='Укажите Залог, адрес, стоимость и т.п'
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='accompanying_documents'
                  label='Загрузите документы (при необходимости)'
                >
                  <Upload
                    name='accompanying_documents'
                    multiple
                    {...props}
                    onChange={(e) => setFile(e)}
                  >
                    <Button icon={<UploadOutlined />} className={s.btn}>
                      Загрузить
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name={"checkbox"}
                  rules={[
                    {
                      required: isChecked ? false : true,
                      message:
                        "Необходимо согласие на обработку персональных данных",
                    },
                  ]}
                >
                  <Checkbox onChange={(e) => setIsChecked(e.target.checked)}>
                    Я cогласен на обработку персональных данных
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <Space className={s.btns}>
            {width > 475 ? (
              <Button onClick={onClose} className={s.btn}>
                Отменить
              </Button>
            ) : null}
            <Button className={s.btn2} htmlType='submit'>
              Отправить
            </Button>
          </Space>
        </Form>
        <div className={s.loader}>
          {loading ? <Loading loading={loading} /> : null}
        </div>
      </Drawer>
    </div>
  );
};

export default ApplicationDrawer;
