import React from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import s from './Projects.module.scss'
import ProjectItem from '../../components/ProjectItem/ProjectItem'


const projects = [
	{
		id: 1,
		img: '/assets/14.jpeg',
		title: 'Строительство образовательного комплекса',
		company: 'ОсОО «Эрудит»',
		category: 'Образование',
		date: '01.12.2023',
		subtitle: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolorum, quos'
	},
	{
		id: 2,
		img: '/assets/13.jpeg',
		title: 'Восстановление Малой ГЭС',
		company: 'ОсОО «Сокулуская ГЭС-1»',
		category: 'Энергетика',
		date: '01.12.2023',
		subtitle: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolorum, quos'
	},
	{
		id: 3,
		img: '/assets/11.jpeg',
		title: 'восстановление Малой ГЭС',
		company: 'ОсОО «Сокулуская ГЭС-1»',
		category: 'Энергетика',
		date: '01.12.2023',
		subtitle: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolorum, quos'
	},
	{
		id: 4,
		img: '/assets/10.jpeg',
		title: 'восстановление Малой ГЭС',
		company: 'ОсОО «Сокулуская ГЭС-1»',
		category: 'Энергетика',
		date: '01.12.2023',
		subtitle: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolorum, quos'
	},
	{
		id: 5,
		img: '/assets/9.jpeg',
		title: 'восстановление Малой ГЭС',
		company: 'ОсОО «Сокулуская ГЭС-1»',
		category: 'Энергетика',
		date: '01.12.2023',
		subtitle: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolorum, quos'
	},
	{
		id: 5,
		img: '/assets/7.jpeg',
		title: 'восстановление Малой ГЭС',
		company: 'ОсОО «Сокулуская ГЭС-1»',
		category: 'Энергетика',
		date: '01.12.2023',
		subtitle: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolorum, quos'
	},

]

const Projects = () => {
  return (
	<div className={s.projects_wrapper}>
		<Header />
		<div className={s.projects}>
			<h2 className={s.h2}>Реализованные проекты</h2>
			<div className={s.content}>
				{
					projects.map(project => (
						<ProjectItem project={project}/>
					))
				}

			</div>

		</div>
		<Footer />
	</div>
  )
}

export default Projects