import React from "react";
import Header from "../../components/Header/Header";
import s from "./Team.module.scss";
import TeamItem from "../../components/TeamItem/TeamItem";
import Footer from "../../components/Footer/Footer";
import ManagementItem from "../../components/ManagmentItem/ManagementItem";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import { useSelector } from "react-redux";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";

const Team = () => {
  const { height, width } = useWindowDimensions();
  const boardMembers = useSelector((state) => state.application.boardMembers);
  const councilMembers = useSelector(
    (state) => state.application.councilMembers
  );

  return (
    <div className={s.team}>
      {width > 930 ? <Header /> : <MobileHeader />}
      <div className={s.line}></div>
      <div className={s.team_content}>
        <div className={s.union}>
          <h2 className={s.h2}>Совет Фонда</h2>
          <div className={s.subtitle}>
            {" "}
            Совет Фонда является высшим органом управления Фонда, осуществляющим
            стратегическое управление его деятельностью. Членами Совета являются
            представители кыргызской и азербайджанской сторон.
          </div>
          <div className={s.management}>
            {Array.isArray(boardMembers) &&
              boardMembers.map((it) => <ManagementItem data={it} />)}
          </div>
        </div>
        <h2 className={s.h2}>Правление Фонда</h2>
        <div className={s.subtitle}>
          Является постоянно действующим коллегиальным исполнительным органом
          Фонда. <br />
          Членами Правления являются представители кыргызской и азербайджанской
          сторон.
        </div>
        <div className={s.team_members}>
          {Array.isArray(councilMembers) &&
            councilMembers.map(
              (it) => it.role === "Сопредседатель" && <TeamItem data={it} />
            )}
        </div>
        <div className={s.team_members}>
          {Array.isArray(councilMembers) &&
            councilMembers.map(
              (it) => it.role === "Член Правления" && <TeamItem data={it} />
            )}
        </div>
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default Team;
