import React from "react";
import s from "./Consultants.module.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Table } from "antd";
import { Link } from "react-router-dom";
import { FilePdfOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getVacancyDetails } from "../../redux/ApplicationSlice";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import ConsultantsMobileTable from "../../components/ConsultantsMobileTable/ConsultantsMobileTable";

const Consultants = () => {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const consultants = useSelector((state) => state.application.consultants);

  const columns = [
    {
      title: "№",
      dataIndex: "number",
      width: 20,
      align: "center",
    },
    {
      title: "Независимые консультанты",
      dataIndex: `consultant`,
      width: 400,
    },
    {
      title: "Контакты",
      dataIndex: `contacts`,
      width: 600,
    },
    {
      title: "Отрасль",
      dataIndex: `field`,
      width: 500,
    },
  ];

  return (
    <div>
      {width > 930 ? <Header /> : <MobileHeader />}
      <div className={s.content}>
        <h2 className={s.h2}>Аккредитованные независимые консультанты</h2>
        {width > 800 ? (
          <Table
            bordered
            pagination={false}
            columns={columns}
            dataSource={consultants?.map((it, index) => ({
              number: `${index + 1}.`,
              consultant: it?.description,
              contacts: (
                <div>
                  <div className={s.contactsItem}>
                    <div className={s.contactTitle}>Тел:</div>
                    <div>{it?.phone}</div>{" "}
                  </div>
                  {it?.email ? (
                    <div className={s.contactsItem}>
                      <div className={s.contactTitle}>Email:</div>
                      <a href={`mailto:${it?.email}`} className={s.site}>
                        {it?.email}
                      </a>
                    </div>
                  ) : null}

                  <div className={s.contactsItem}>
                    {it.website ? (
                      <div className={s.contactsItem}>
                        <div className={s.contactTitle}>Cайт:</div>
                        <Link to={`${it?.website}`} className={s.site}>
                          {it?.website}
                        </Link>
                      </div>
                    ) : null}
                  </div>
                </div>
              ),
              field: it?.Industry,
            }))}
            className='partner_table'
          />
        ) : (
          <ConsultantsMobileTable />
        )}
      </div>

      <div className={s.documents_btns}>
        <Link
          to='/vacancies/1'
          className={s.title}
          onClick={() => dispatch(getVacancyDetails(1))}
        >
          <FilePdfOutlined className={s.icon} /> &nbsp; ОБЪЯВЛЕНИЕ О НАБОРЕ
          НЕЗАВИСИМЫХ КОНСУЛЬТАНТОВ
        </Link>
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default Consultants;
